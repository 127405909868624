import { slugifyOfferName } from '@dx-ui/content-offers-components';
import type { StaticOfferQuery } from '@dx-ui/content-offers-components';
import type { NextRouter } from 'next/router';
import { breakpoints } from '../components/responsive-image/breakpoints';

export const getScreenSize = (width: number) => {
  const isMobile = width < breakpoints['sm'];
  const isTablet = !isMobile && width <= breakpoints['md'];
  const isDesktop = !isMobile && !isTablet;
  return { isMobile, isTablet, isDesktop };
};

export function getCanonicalUrl({
  offer,
  router,
  baseUrl,
}: {
  offer: StaticOfferQuery;
  router: NextRouter;
  baseUrl: string;
}) {
  if (offer?.staticOffer?.type === 'brand') {
    return `${baseUrl}/${router.locale}/offers/${slugifyOfferName(offer.staticOffer)}/`;
  }
  // otherwise this the canonical url should be itself
  return `${baseUrl}/${router.locale}${router.asPath}`;
}
