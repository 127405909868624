import { useRouter } from 'next/router';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import CtaCard from '../cta-cards/cta-card';
import MainImage from '../main-image/MainImage';
import {
  generateUrl,
  getConsolidatedHours,
  getConsolidatedHoursOfOperation,
  restaurantNameToUrl,
} from '../../helpers/property-utils';
import type { GetHotelDiningPageQuery } from '../../generated/types';
import { DaysAndTimeSection } from '../restaurant-hours/DaysAndTimeSection';
import { useMetrics } from '../../hooks/use-metrics';

type TDiningGridProps = {
  restaurantOverview: NonNullable<GetHotelDiningPageQuery['hotel']>['restaurantOverview'];
};

const DiningGrid = ({ restaurantOverview }: TDiningGridProps) => {
  const { relativeUrl } = generateUrl(useRouter(), 'dining');
  const { t } = useTranslation('dining');
  const metrics = useMetrics();

  return (
    <>
      <MainImage image={restaurantOverview?.image} />
      <h2 className="heading-2xl lg:heading-4xl m-0 flex justify-center p-0 py-8 text-center">
        {t('header')}
      </h2>
      {restaurantOverview?.desc && (
        <div className="mx-auto max-w-4xl">
          <p className="p-4 text-center">{restaurantOverview.desc}</p>
        </div>
      )}
      <div className="container pb-6">
        <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {restaurantOverview?.restaurants
            .filter((restaurants) => restaurants.description !== null)
            .map((restaurant) => {
              const consolidatedHours = restaurant?.hoursOfOperation
                ? getConsolidatedHoursOfOperation(restaurant.hoursOfOperation)
                : getConsolidatedHours(restaurant.operatingHours);

              return (
                <CtaCard
                  images={restaurant.halfAndHalfImages}
                  headerTitle={restaurant.name_noTx || ''}
                  key={restaurant.name_noTx}
                  summaryText={restaurant.description || ''}
                  accordionLabel={<span className="text-primary font-bold">{t('hours')}</span>}
                  primaryButton={
                    restaurant.diningMenus?.length
                      ? {
                          buttonText:
                            restaurant.diningMenus.length > 1 ? t('viewMenus') : t('viewMenu'),
                          altText:
                            restaurant.diningMenus.length > 1
                              ? t('viewMenusFor', { restaurantName: restaurant.name_noTx })
                              : t('viewMenuFor', { restaurantName: restaurant.name_noTx }),
                          buttonLink: `${relativeUrl}${restaurantNameToUrl(
                            restaurant.name_noTx || ''
                          )}/`,
                          buttonOnClick: () => {
                            if (!metrics) return null;
                            metrics.trackEvent('property_click');
                            metrics.trackElemClickId('view_menu');
                          },
                        }
                      : null
                  }
                  secondaryButton={restaurant.diningMenus?.length ? null : restaurant?.link}
                >
                  {consolidatedHours.length > 0 ? (
                    <div className="-mb-2 mt-1">
                      {consolidatedHours.map((consolidatedHour, index) => (
                        <div
                          className={cx('px-3 py-1', {
                            'bg-bg-alt': index % 2 === 1,
                            'bg-bg-disabled': index % 2 !== 1,
                          })}
                          key={consolidatedHour?.days[0]?.day}
                        >
                          <DaysAndTimeSection daysAndTimes={consolidatedHour} />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </CtaCard>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default DiningGrid;
