import cx from 'classnames';
import { useRef } from 'react';
import { Accordion } from '@dx-ui/osc-accordion';
import type { GetHotelDiningPageQuery } from '../../generated/types';
import { CarouselSingle } from '@dx-ui/osc-carousel';
import { structuredCarouselImages } from '../../helpers/property-utils';
import { useRect } from '@dx-ui/utilities-use-rect';
import { BrandLink } from '../brand-buttons/brand-buttons';

type TButtonData = {
  buttonText: string;
  buttonLink: string;
  altText: string;
  buttonOnClick?: () => void;
};

export type DiningImage = NonNullable<
  NonNullable<GetHotelDiningPageQuery['hotel']>['restaurantOverview']
>['restaurants'][number]['halfAndHalfImages'];

type TCtaCardProps = {
  children?: React.ReactNode;
  images?: DiningImage;
  primaryButton?: TButtonData | null;
  accordionLabel?: string | React.ReactNode;
  secondaryButton?: NonNullable<
    NonNullable<GetHotelDiningPageQuery['hotel']>['restaurantOverview']
  >['restaurants'][number]['link'];
  headerTitle: string;
  summaryText: string;
};

const CtaCard = ({
  children,
  images,
  headerTitle,
  accordionLabel,
  primaryButton,
  secondaryButton,
  summaryText,
}: TCtaCardProps) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });

  return (
    <div
      data-testid="cta-card"
      className="border-border bg-bg flex min-w-[325px] flex-1 flex-col justify-between rounded-md border-2"
    >
      <div ref={ref}>
        <CarouselSingle
          className="rounded-t-md"
          images={images?.length ? structuredCarouselImages(images, rect?.width) : undefined}
        />
        <h3 className="OneLinkNoTx px-4 py-2 text-xl font-bold">{headerTitle}</h3>
        <div className="px-4">
          <hr
            className={cx('text-border-alt', {
              'pb-2.5': children,
            })}
            aria-hidden={true}
          />
          {children && (
            <>
              <Accordion
                className="accordion-single text-text no-underline"
                collapsedButtonLabel={accordionLabel}
                collapsedButtonAccessibleLabel={`Show hours for ${headerTitle}`}
                data-testid="accordion-button"
                expandedButtonLabel={accordionLabel}
                expandedButtonAccessibleLabel={`Hide hours for ${headerTitle}`}
              >
                {children}
              </Accordion>
              <hr className="text-border-alt mt-2" />
            </>
          )}
        </div>
        <p className="p-4">{summaryText}</p>
      </div>
      <div className="mb-4 flex w-full flex-wrap px-4">
        {primaryButton ? (
          <BrandLink
            adaDescription={primaryButton?.altText}
            label={primaryButton?.buttonText}
            url={primaryButton?.buttonLink}
            onClick={primaryButton?.buttonOnClick}
            data-testid="cta-primary-button"
            anchorClassName="w-full flex justify-center"
          />
        ) : null}
        {secondaryButton ? (
          <BrandLink
            adaDescription={secondaryButton.adaDescription || ''}
            label={secondaryButton.label || ''}
            url={secondaryButton.url || ''}
            isNewWindow={secondaryButton.isNewWindow || false}
            showNewWindowIcon={false}
            data-testid="cta-secondary-button"
            anchorClassName={cx('w-full flex justify-center', {
              'mt-4': !!primaryButton,
            })}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CtaCard;
