import { useRef } from 'react';
import { useRect } from '@dx-ui/utilities-use-rect';
import { getScreenSize } from '../../helpers/utils';
import { getHotelImageVariant } from '../../helpers/property-utils';
import { Image } from '../image/Image';
import type { HotelImageVariantSize, Maybe } from '@dx-ui/gql-types';

export type TMainImage = {
  image?: {
    altText?: Maybe<string>;
    variants: { size: HotelImageVariantSize; url?: Maybe<string> }[];
  } | null;
};

const MainImage: React.FC<TMainImage> = ({ image }) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const { isDesktop } = getScreenSize(rect?.width ?? 0);
  const imageVariant =
    image && image.variants && getHotelImageVariant(image.variants, rect?.width ?? 0);

  return (
    <div ref={ref} data-testid="mainImage" className="relative">
      <div className="z-1 absolute inset-0 opacity-50" />
      <div className="flex flex-col justify-center overflow-hidden" data-testid="main-image-wrap">
        {imageVariant ? (
          <Image
            alt={image.altText || ''}
            src={imageVariant.url || ''}
            width={rect?.width ?? 0}
            aspectRatio={isDesktop ? '18:5' : '3:2'}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MainImage;
