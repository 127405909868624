import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import { getServerSideClients } from '@dx-ui/framework-react-query';
import { getCtyhocnFromSlug, getHotelsRouteParams } from '../../../../helpers/routing';
import { GLOBAL_NAMESPACES, HotelPage } from '../../../../helpers/constants';
import {
  getDehydratedQueryState,
  serverSideLayoutAndRedirects,
} from '../../../../helpers/serverSideHelpers';
import { serverSideGetHotelDiningPageQuery } from '../../../../generated/react-query';
import { getLayoutData } from '../../../../helpers/layout';
import { useRouter } from 'next/router';
import DiningGrid from '../../../../components/dining-grid/DiningGrid';
import nextI18nextConfig from '../../../../next-i18next.config';
import HotelLayout from '../../../../components/layout/HotelLayout';
import type { StandardSchema } from '../../../../components/head/page-schemas';
import { GetBrandSvg } from '../../../../helpers/assets';
import { CustomHead } from '../../../../components/head';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { useStaticUrls } from '../../../../hooks/useStaticUrls';
import { getDefaultBrandTheme } from '../../../../helpers/themes/defaultTheme';
import { FeatureToggles, isFeatureToggleEnabled } from '../../../../helpers/toggles';

const pageName = HotelPage.DINING;
const namespaces = [...GLOBAL_NAMESPACES, 'half-and-half', 'osc-accordion', pageName];

const DiningPage = ({
  restaurantOverview,
  layout,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  const { baseAppUrl } = useStaticUrls();

  const { ctyhocn } = getHotelsRouteParams(router);

  const standardSchema: StandardSchema = {
    address: layout?.address,
    coordinates: layout?.coordinates,
    image: layout?.image || '',
    openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su',
    schemaType: 'Hotel',
    description: layout?.seoMetaData.desc,
    name: layout?.hotelName,
    url: baseAppUrl,
    telephone: layout?.phone || '',
  };

  return (
    <HotelLayout layout={layout} page={pageName} ctyhocn={ctyhocn}>
      <CustomHead
        brandLogoImageSrc={GetBrandSvg(layout?.headerData.brandCode)}
        schema={standardSchema}
        metaDescription={layout?.seoMetaData.desc}
        metaKeywords={layout?.seoMetaData.keywords}
        page={pageName}
        pageTitle={layout?.seoMetaData.title}
        brandCode={layout?.headerData.brandCode}
        shouldUseDefaultBrandFavicon={layout?.headerData?.suppressLogo}
      />
      <DiningGrid restaurantOverview={restaurantOverview} />
    </HotelLayout>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { res, params, locale } = context;
  const ctyhocn = getCtyhocnFromSlug(params?.hotelSlug);

  const envVars = getQueryProviderProps();
  const { queryClient } = getServerSideClients({
    ...envVars,
    appName: 'dx-property-ui',
    response: res,
  });

  const {
    layoutResult,
    mainNavLinks,
    resEnabled,
    notFound,
    oneLinkProps,
    originalLocale,
    featureToggles,
  } = await serverSideLayoutAndRedirects({
    queryClient,
    ctyhocn,
    pageName,
    hotelSlug: params?.hotelSlug as string,
    context,
  }).catch((e) => {
    console.log('Error in serverSideLayoutAndRedirects', e); // eslint-disable-line no-console

    return {
      layoutResult: null,
      mainNavLinks: null,
      resEnabled: null,
      notFound: true,
      oneLinkProps: null,
      originalLocale: null,
      featureToggles: null,
    };
  });

  if (notFound) {
    return { notFound: true };
  }

  const layout = getLayoutData({
    layoutResult,
    resEnabled,
    mainNavLinks,
    locale: originalLocale || locale,
  });

  const brandCode = layoutResult?.hotelPageTemplate?.hotel?.brandCode || '';

  const diningResult = await serverSideGetHotelDiningPageQuery(queryClient, {
    ctyhocn,
  }).catch((e) => {
    console.log('Error in serverSideGetHotelDiningPageQuery', e); // eslint-disable-line no-console
    return null;
  });

  const isEmbassyRefreshToggleEnabled = isFeatureToggleEnabled({
    featureToggleName: FeatureToggles.NHCBP_5435_Embassy_Refresh,
    data: featureToggles,
  });

  if (!diningResult) {
    return { notFound: true };
  }

  return {
    props: {
      restaurantOverview: diningResult?.hotel?.restaurantOverview,
      layout,
      themeColors: layout.theme ?? getDefaultBrandTheme(brandCode),
      isEmbassyRefreshToggleEnabled,
      ...(await serverSideTranslations('en', namespaces, nextI18nextConfig)),
      ...oneLinkProps,
      dehydratedQueryState: getDehydratedQueryState(queryClient),
      metrics: {
        trackingData: {
          pageData: {
            pageName,
            ctyhocn,
            destinationUrl: layout.homeUrlPath,
          },
        },
      },
    },
  };
}

export default DiningPage;
